/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Normalize
# Typography
# Elements
# Forms
# Navigation
	## Links
	## Menus
# Accessibility
# Alignments
# Clearings
# Widgets
# Content
	## Posts and pages
	## Comments
# Infinite scroll
# Media
	## Captions
	## Galleries
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
body,
button,
input,
select,
optgroup,
textarea {
  color: #404040;
  font-family: sans-serif;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5; }

h1, h2, h3, h4, h5, h6 {
  clear: both; }

p {
  margin-bottom: 1.5em; }

dfn, cite, em, i {
  font-style: italic; }

blockquote {
  margin: 0 1.5em; }

address {
  margin: 0 0 1.5em; }

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em; }

code, kbd, tt, var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 15px;
  font-size: 0.9375rem; }

abbr, acronym {
  border-bottom: 1px dotted #666;
  cursor: help; }

mark, ins {
  background: #fff9c0;
  text-decoration: none; }

big {
  font-size: 125%; }

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit; }

body {
  background: #fff;
  /* Fallback for when there is no custom background color defined. */ }

hr {
  background-color: #ccc;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em; }

ul, ol {
  margin: 0 0 1.5em 3em; }

ul {
  list-style: disc; }

ol {
  list-style: decimal; }

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em; }

dt {
  font-weight: bold; }

dd {
  margin: 0 1.5em 1.5em; }

img {
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */ }

figure {
  margin: 1em 0;
  /* Extra wide images within figure tags don't overflow the content area. */ }

table {
  margin: 0 0 1.5em;
  width: 100%; }

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  border: 1px solid;
  border-color: #ccc #ccc #bbb;
  border-radius: 3px;
  background: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1;
  padding: .6em 1em .4em; }

button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  border-color: #ccc #bbb #aaa; }

button:active, button:focus,
input[type="button"]:active,
input[type="button"]:focus,
input[type="reset"]:active,
input[type="reset"]:focus,
input[type="submit"]:active,
input[type="submit"]:focus {
  border-color: #aaa #bbb #bbb; }

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  color: #666;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 3px; }

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
  color: #111; }

select {
  border: 1px solid #ccc; }

textarea {
  width: 100%; }

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
a {
  color: #ffea01;
  text-decoration: none; }

a:hover, a:focus, a:active {
  text-decoration: none;
  outline: none; }

a:focus {
  text-decoration: none;
  outline: none; }

a:hover, a:active {
  outline: 0; }

body {
  font-family: 'Quattrocento', serif;
  font-weight: 400;
  font-size: 14px;
  color: #2d2d2d;
  line-height: 20px; }

h1, h2, h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #273171;
  word-spacing: 5px; }

h2 {
  font-size: 28px;
  line-height: 28px; }

h3 {
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0px 0px 10px; }

h4 {
  font-size: 18px; }

p {
  margin: 0px 0px 10px; }

body {
  background: #000 url(../media/background-img.jpg) no-repeat top;
  background-size: cover;
  position: relative;
  width: 100%;
  float: left; }

body:before {
  content: "";
  display: inline-block;
  width: 100%;
  height: 380px;
  background-color: rgba(0, 0, 0, 0.55);
  position: absolute;
  top: 0px;
  left: 0px; }

.site-content, .site-header, .site-footer {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  position: relative; }

/*===================== header start =====================*/
.site-header {
  width: 100%;
  z-index: 5; }

.site-header .header-top {
  width: 100%;
  text-align: center;
  float: left;
  padding: 20px 0px; }

.top-address {
  display: inline-block;
  margin-top: 10px;
  text-align: center;
  width: 100%; }

.top-address ul {
  margin: 0px;
  padding: 0px; }

.top-address ul li {
  display: inline-block;
  color: #ffea01;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0px 10px;
  position: relative;
  font-size: 18px; }

.top-address ul li:before {
  content: ".";
  display: inline-block;
  position: absolute;
  left: -4px;
  top: -4px; }

.top-address ul li:first-child:before {
  display: none; }

.main-navigation {
  width: 100%;
  float: left;
  margin-bottom: 10px; }

.main-navigation ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }

.main-navigation ul li {
  display: inline-block;
  position: relative; }

.main-navigation ul li a {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  padding: 10px 35px;
  display: inline-block; }

.main-navigation ul li button {
  color: #fff;
  height: 40px;
  width: 40px;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  display: none; }

.main-navigation ul li button span {
  position: absolute;
  left: -999em;
  top: -999em; }

.main-navigation ul li > a:hover, .main-navigation ul li.current-page-ancestor > a, .main-navigation ul li.current_page_item > a {
  color: #ffea01; }

.main-navigation .sub-menu {
  background: #c72026;
  display: block;
  left: -999em;
  min-width: 300px;
  opacity: 0;
  position: absolute;
  top: 55px;
  transition: opacity 0.1s 0s, left 0s 0.1s, top 0s 0.1s;
  z-index: 1; }

.main-navigation li.is-active > .sub-menu, .main-navigation li:hover > .sub-menu {
  left: 0;
  opacity: 1;
  transition: opacity 0s, left 0s, top 0s; }

.main-navigation .sub-menu li {
  width: 100%; }

.main-navigation .sub-menu li:hover {
  background-color: #b11c22; }

.main-navigation .sub-menu a {
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  transition: background-color 0.1s;
  width: 100%;
  height: 100%;
  margin: 0; }

.main-navigation .sub-menu li.is-active > .sub-menu, .main-navigation .sub-menu li:hover > .sub-menu {
  left: 100%;
  margin-left: 0;
  top: 0; }

.main-navigation .sub-menu .sub-menu li:hover {
  background-color: #9b191e; }

/*===================== banner start =====================*/
.banner-section {
  width: 100%;
  float: left;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.75);
  margin-bottom: 40px;
  overflow: hidden; }

.banner-section .banner-img {
  width: 100%;
  max-width: 785px;
  float: left;
  background-size: cover;
  background-repeat: no-repeat; }

.banner-section .banner-img img {
  width: 100%;
  float: left;
  opacity: 0; }

.banner-section .banner-content {
  width: 355px;
  float: left;
  padding: 30px 32px 40px 22px;
  position: relative; }

.banner-section .banner-content:before {
  content: "";
  display: inline-block;
  background: url(../media/bottom-logo.png) no-repeat bottom right;
  width: 205px;
  height: 200px;
  position: absolute;
  bottom: -91px;
  right: -20px;
  opacity: 0.9; }

.banner-section .btn-about {
  background-color: #273171;
  max-width: 100%;
  padding: 9px 15px;
  font-size: 18px;
  color: #ffea01;
  text-transform: uppercase;
  display: inline-block;
  width: calc(100% + 32px);
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  line-height: 20px;
  position: relative;
  z-index: 1;
  margin-top: 5px;
  letter-spacing: 1px; }

/*========================== content section ========================*/
.bottom-section {
  width: 100%;
  float: left;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.75);
  margin-bottom: 20px;
  overflow: hidden;
  padding: 30px 20px 25px;
  display: flex; }

body:not(.home) .bottom-section h1, .address-block h3 {
  font-size: 1.7em;
  text-transform: uppercase;
  line-height: 1em;
  margin-top: 0;
  margin-bottom: 0.67em; }

body:not(.home) .bottom-section h2, .bottom-section h3, .bottom-section h4, .bottom-section h5, .bottom-section h6 {
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  margin: 0 0 0.5em;
  line-height: 1em; }

body:not(.home) .bottom-section h1 {
  font-size: 1.5em; }

body:not(.home) .bottom-section h2 {
  font-size: 1.2em; }

body:not(.home) .bottom-section h3 {
  font-size: 1em; }

body:not(.home) .bottom-section h4 {
  font-size: 1em;
  color: #ed1c24; }

body:not(.home) .bottom-section h5 {
  font-size: 0.8em; }

body:not(.home) .bottom-section h6 {
  font-size: 0.6em; }

body:not(.home) .bottom-section .our-station h3 {
  font-size: 1.5em; }

.bottom-section ul, .bottom-section ol {
  margin: 0 0 1.5em 0; }

.bottom-section a {
  color: #ed1c24; }

.bottom-section a:hover {
  color: #c61017; }

.bottom-section a.button {
  background: #ed1c24;
  border-radius: 8px;
  color: #ffffff;
  font-size: 120%;
  font-weight: bold;
  padding: 8px 12px;
  transition: color 0.15s, background-color 0.15s; }

.bottom-section a.button:hover {
  background: #c61017;
  color: #ffffff; }

.bottom-section .colum-block {
  flex: 1 1 0; }

.bottom-section .colum-block.one-third {
  flex: 1 1 33%; }

.bottom-section .colum-block.two-third {
  flex: 1 1 66%; }

.bottom-section .colum-block h3 {
  margin-bottom: 15px;
  letter-spacing: 1px; }

.bottom-section .alignright, .bottom-section .alignleft, .bottom-section .aligncenter, .bottom-section .alignnone {
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.21);
  display: block;
  padding: 5px; }

.bottom-section .alignright {
  float: right;
  margin-left: 3.5em; }

.bottom-section .alignleft {
  float: left;
  margin-right: 3.5em; }

.bottom-section .aligncenter {
  margin: 0 auto; }

address {
  font-style: normal; }

.bottom-section .colum-block.address-block {
  border-right: 1px solid #e0dfdf;
  margin-right: 20px;
  padding-right: 20px; }

.address-block address {
  margin: 10px 0px 0px; }

.address-block address p:last-child {
  margin: 0px; }

.address-block address a {
  color: #2d2d2d; }

.bottom-section .event-list-block {
  max-width: 440px;
  width: 100%;
  float: left;
  border-right: 1px solid #e0dfdf; }

.bottom-section .colum-block .row [class*="col-"]:not(:last-child) {
  padding-right: 20px; }

.event-list-block .event-block {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  line-height: 16px; }

.event-list-block .event-block .event-date {
  width: 55px;
  padding-right: 15px;
  float: left; }

.event-list-block .event-block .event-date span {
  font-size: 18px;
  color: #a9a9a9;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  display: inline-block;
  font-family: 'Poppins', sans-serif; }

.event-list-block .event-block .event-date span big {
  font-size: 30px; }

.event-list-block .event-block .event-content h4 {
  margin: 0px;
  line-height: 0px; }

.event-list-block .event-block .event-content h4 a {
  color: #ed1c24;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  text-transform: uppercase; }

.event-list-block .event-block .event-content p {
  line-height: 16px; }

.public-education {
  padding: 40px 0; }

.public-education .public-block {
  margin-bottom: 20px;
  width: 100%; }

.public-education .public-block h4 {
  margin: 0px;
  font-weight: bold;
  text-transform: uppercase; }

.public-education .public-block a {
  color: #ed1c24;
  font-weight: bold; }

/*========================== Footer start ====================*/
.site-footer {
  width: 100%;
  margin-bottom: 60px;
  overflow: hidden; }

.site-footer .footer-left {
  width: 50%;
  float: left; }

.site-footer .footer-left p {
  color: #fff;
  margin-bottom: 5px; }

.site-footer .footer-left a.emergency-no {
  color: #ffea01;
  text-transform: uppercase; }

.site-footer .footer-right {
  width: 50%;
  float: left; }

.site-footer .footer-right ul {
  float: right;
  margin: 0px;
  padding: 0px;
  text-align: right; }

.site-footer .footer-right ul li {
  display: inline-block;
  margin-left: 15px; }

.site-footer .footer-right ul li a {
  color: #ffea01;
  padding: 1px 0;
  display: inline-block; }

.site-footer .footer-right ul li a:hover {
  text-decoration: underline; }

.mega-menu {
  display: none; }

.inner-page .bottom-section {
  padding: 0px; }

.news-events {
  width: 100%;
  float: left;
  padding: 15px 40px 70px 50px; }

.news-events .news-events-content {
  width: 70%;
  float: left;
  padding-right: 30px; }

.news-events .news-events-img {
  width: 30%;
  float: left;
  padding-left: 20px; }

.news-events .news-events-img .news-img {
  border: 5px solid #ffffff;
  width: 100%;
  float: left;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px; }

.news-events .news-events-img .news-img img {
  width: 100%;
  display: block; }

.our-station {
  width: 100%;
  float: left;
  max-width: 395px;
  background-color: #e8e8e8;
  padding: 15px 35px 70px 60px;
  position: relative; }

.our-station h3, .address-block address {
  position: relative; }

.our-station h3 {
  margin-bottom: 0.67em;
  font-size: 1.5em; }

div#map-canvas {
  width: 100% !important;
  border: 1px solid #a9a9a9; }

#infocontent .sub_title a {
  color: #ed1c24; }

/* ------------------------------------------------------------------------ * * Grid (based loosely on https://v4-alpha.getbootstrap.com/layout/grid/)
\* ------------------------------------------------------------------------ */
.row {
  display: flex;
  flex-wrap: wrap; }

.row > [class*="col-"] {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0; }

.row > [class*="col-"].-nogrow {
  flex-grow: 0; }

.row > [class*="col-"].-noshrink {
  flex-shrink: 0; }

.row.-padded {
  width: calc(100% + $column-gap); }

.row.-padded > [class*="col-"] {
  padding-right: 30px; }

.row.-padded.-tight {
  width: calc(100% + 22.5px); }

.row.-padded.-tight > [class*="col-"] {
  padding-right: 22.5px; }

.row.-padded.-loose {
  width: calc(100% + 37.5px); }

.row.-padded.-loose > [class*="col-"] {
  padding-right: 37.5px; }

.row.-nowrap {
  flex-wrap: nowrap; }

.row.-wrapreverse {
  flex-wrap: wrap-reverse; }

.row.-center {
  align-items: center;
  justify-content: center; }

.row:not(.-column).-hcenter {
  justify-content: center; }

.row:not(.-column).-vcenter {
  align-items: center; }

.row:not(.-column).-top {
  align-items: flex-start; }

.row:not(.-column).-right {
  justify-content: flex-end; }

.row:not(.-column).-bottom {
  align-items: flex-end; }

.row:not(.-column).-left {
  justify-content: flex-start; }

.row.-stretch {
  align-items: stretch; }

.row.-around {
  justify-content: space-around; }

.row.-between {
  justify-content: space-between; }

.row:not(.-column).-reverse {
  flex-direction: row-reverse; }

.row.-column {
  flex-direction: column; }

.row.-column.-vcenter {
  justify-content: center; }

.row.-column.-top {
  justify-content: flex-start; }

.row.-column.-right {
  align-items: flex-end; }

.row.-column.-bottom {
  justify-content: flex-end; }

.row.-column.-left {
  align-items: flex-start; }

.row.-column.-hcenter {
  align-items: center; }

.row.-column.-reverse {
  flex-direction: column-reverse; }

.col-auto {
  flex-basis: auto; }

.col-0 {
  flex-basis: 0%; }

.col-1 {
  flex-basis: 8.33333%; }

.col-2 {
  flex-basis: 16.66667%; }

.col-3 {
  flex-basis: 25%; }

.col-4 {
  flex-basis: 33.33333%; }

.col-5 {
  flex-basis: 41.66667%; }

.col-6 {
  flex-basis: 50%; }

.col-7 {
  flex-basis: 58.33333%; }

.col-8 {
  flex-basis: 66.66667%; }

.col-9 {
  flex-basis: 75%; }

.col-10 {
  flex-basis: 83.33333%; }

.col-11 {
  flex-basis: 91.66667%; }

.col-12 {
  flex-basis: 100%; }

@media screen and (min-width: 768px) {
  .col-xs-auto {
    flex-basis: auto; }
  .col-xs-0 {
    flex-basis: 0%; }
  .col-xs-1 {
    flex-basis: 8.33333%; }
  .col-xs-2 {
    flex-basis: 16.66667%; }
  .col-xs-3 {
    flex-basis: 25%; }
  .col-xs-4 {
    flex-basis: 33.33333%; }
  .col-xs-5 {
    flex-basis: 41.66667%; }
  .col-xs-6 {
    flex-basis: 50%; }
  .col-xs-7 {
    flex-basis: 58.33333%; }
  .col-xs-8 {
    flex-basis: 66.66667%; }
  .col-xs-9 {
    flex-basis: 75%; }
  .col-xs-10 {
    flex-basis: 83.33333%; }
  .col-xs-11 {
    flex-basis: 91.66667%; }
  .col-xs-12 {
    flex-basis: 100%; } }

@media screen and (min-width: 853px) {
  .col-s-auto {
    flex-basis: auto; }
  .col-s-0 {
    flex-basis: 0%; }
  .col-s-1 {
    flex-basis: 8.33333%; }
  .col-s-2 {
    flex-basis: 16.66667%; }
  .col-s-3 {
    flex-basis: 25%; }
  .col-s-4 {
    flex-basis: 33.33333%; }
  .col-s-5 {
    flex-basis: 41.66667%; }
  .col-s-6 {
    flex-basis: 50%; }
  .col-s-7 {
    flex-basis: 58.33333%; }
  .col-s-8 {
    flex-basis: 66.66667%; }
  .col-s-9 {
    flex-basis: 75%; }
  .col-s-10 {
    flex-basis: 83.33333%; }
  .col-s-11 {
    flex-basis: 91.66667%; }
  .col-s-12 {
    flex-basis: 100%; } }

@media screen and (min-width: 960px) {
  .col-m-auto {
    flex-basis: auto; }
  .col-m-0 {
    flex-basis: 0%; }
  .col-m-1 {
    flex-basis: 8.33333%; }
  .col-m-2 {
    flex-basis: 16.66667%; }
  .col-m-3 {
    flex-basis: 25%; }
  .col-m-4 {
    flex-basis: 33.33333%; }
  .col-m-5 {
    flex-basis: 41.66667%; }
  .col-m-6 {
    flex-basis: 50%; }
  .col-m-7 {
    flex-basis: 58.33333%; }
  .col-m-8 {
    flex-basis: 66.66667%; }
  .col-m-9 {
    flex-basis: 75%; }
  .col-m-10 {
    flex-basis: 83.33333%; }
  .col-m-11 {
    flex-basis: 91.66667%; }
  .col-m-12 {
    flex-basis: 100%; } }

@media screen and (min-width: 1024px) {
  .col-l-auto {
    flex-basis: auto; }
  .col-l-0 {
    flex-basis: 0%; }
  .col-l-1 {
    flex-basis: 8.33333%; }
  .col-l-2 {
    flex-basis: 16.66667%; }
  .col-l-3 {
    flex-basis: 25%; }
  .col-l-4 {
    flex-basis: 33.33333%; }
  .col-l-5 {
    flex-basis: 41.66667%; }
  .col-l-6 {
    flex-basis: 50%; }
  .col-l-7 {
    flex-basis: 58.33333%; }
  .col-l-8 {
    flex-basis: 66.66667%; }
  .col-l-9 {
    flex-basis: 75%; }
  .col-l-10 {
    flex-basis: 83.33333%; }
  .col-l-11 {
    flex-basis: 91.66667%; }
  .col-l-12 {
    flex-basis: 100%; } }

@media screen and (min-width: 1280px) {
  .col-xl-auto {
    flex-basis: auto; }
  .col-xl-0 {
    flex-basis: 0%; }
  .col-xl-1 {
    flex-basis: 8.33333%; }
  .col-xl-2 {
    flex-basis: 16.66667%; }
  .col-xl-3 {
    flex-basis: 25%; }
  .col-xl-4 {
    flex-basis: 33.33333%; }
  .col-xl-5 {
    flex-basis: 41.66667%; }
  .col-xl-6 {
    flex-basis: 50%; }
  .col-xl-7 {
    flex-basis: 58.33333%; }
  .col-xl-8 {
    flex-basis: 66.66667%; }
  .col-xl-9 {
    flex-basis: 75%; }
  .col-xl-10 {
    flex-basis: 83.33333%; }
  .col-xl-11 {
    flex-basis: 91.66667%; }
  .col-xl-12 {
    flex-basis: 100%; } }

@media screen and (max-width: 1200px) {
  .site-content, .site-header, .site-footer {
    max-width: 930px; }
  .banner-section .banner-img {
    max-width: 565px; }
  .main-navigation ul li a {
    padding: 10px 15px; }
  .banner-section .banner-content {
    padding: 20px 22px 20px 22px; }
  .bottom-section .event-list-block {
    max-width: 350px;
    padding: 0px 24px 0px 20px; }
  .news-events {
    width: 100%;
    float: left;
    padding: 40px 20px 70px 30px; }
  .news-events .news-events-content {
    padding-right: 10px; }
  .our-station {
    max-width: 315px;
    padding: 40px 25px 70px 25px; } }

@media screen and (max-width: 992px) {
  .site-content, .site-header, .site-footer {
    max-width: 740px; }
  .mobile-icon a, .header-call a {
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase; }
  .mobile-icon a i, .header-call a i {
    margin-right: 10px;
    display: inline-block; }
  .mega-menu {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px; }
  .main-navigation {
    position: fixed;
    right: -250px;
    top: 0;
    height: 100%;
    width: 250px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    z-index: 19;
    background: rgba(0, 0, 0, 0.9);
    text-align: left;
    overflow: auto;
    padding: 30px 0;
    margin: 0;
    overflow-x: hidden; }
  .open-menu .main-navigation {
    right: 0px; }
  .open-menu .fa-bars:before {
    content: "\f00d"; }
  .site {
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    position: relative;
    left: 0; }
  .open-menu .site {
    left: -240px; }
  .main-navigation ul {
    text-align: left;
    display: block;
    width: 100%; }
  .main-navigation ul li {
    display: flex;
    flex-wrap: wrap;
    min-width: 0;
    width: 100%; }
  .main-navigation ul li a {
    font-size: 16px;
    flex: 1 1 0%;
    min-width: 0; }
  .main-navigation ul li button {
    display: inline-block;
    flex: 0 0 auto; }
  .main-navigation .sub-menu {
    background: transparent;
    display: none;
    flex: 0 0 100%;
    left: 0;
    padding-left: 20px;
    overflow: hidden;
    opacity: 1;
    position: static;
    top: 0;
    box-sizing: border-box;
    width: 100%;
    min-width: 0; }
  .main-navigation li.is-active .sub-menu {
    display: block; }
  .header-call {
    display: inline-block; }
  .banner-section .banner-content {
    width: 100%; }
  .banner-section .banner-img {
    max-width: 100%; }
  .bottom-section {
    display: block; }
  .bottom-section .colum-block.address-block, .colum-block.public-education, .bottom-section .event-list-block {
    max-width: 100%;
    border-right: none;
    border-bottom: 1px solid #e0dfdf;
    padding: 0px 0px 20px;
    margin-bottom: 30px;
    width: 100%; }
  .colum-block.public-education:last-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px; }
  body:before {
    height: 245px; }
  .our-station {
    padding: 0px 25px 30px 25px;
    background-color: #fff;
    max-width: 100%; }
  .our-station:before {
    left: -30px; }
  .news-events {
    padding: 30px 20px 30px 30px;
    max-width: 100%; }
  .banner-section .btn-about {
    width: auto;
    padding: 9px 20px; } }

@media screen and (max-width: 767px) {
  .site-content, .site-header, .site-footer {
    max-width: 100%;
    padding: 0px 20px; }
  body {
    background-color: #fff;
    background-size: 100% auto;
    position: relative; }
  .top-address ul li {
    padding: 0px 4px;
    font-size: 8px; }
  .site-header .header-top {
    padding: 10px 0px; }
  body:before {
    height: 130px; }
  .banner-section {
    box-shadow: none;
    margin-bottom: 0px;
    margin-top: 10px; }
  .banner-section .banner-content {
    padding: 20px 0px 20px 0px; }
  h3 {
    font-size: 21px; }
  .banner-section .btn-about {
    width: 100%;
    float: left;
    text-align: center; }
  .banner-section .banner-content:before {
    display: none; }
  .bottom-section {
    box-shadow: none;
    padding: 30px 0px 20px; }
  .bottom-section .colum-block.address-block, .colum-block.public-education, .bottom-section .event-list-block {
    border-bottom: none;
    padding: 0px; }
  .bottom-section .alignright, .bottom-section .alignleft, .bottom-section .aligncenter, .bottom-section .alignnone {
    float: none;
    margin: 0 auto; }
  .site-footer {
    background-color: #080b16;
    text-align: center; }
  .site-footer .footer-left {
    width: 100%; }
  .site-footer .footer-right {
    width: 100%; }
  .site-footer .footer-right ul {
    float: none;
    text-align: center; }
  .site-footer {
    background-color: #080b16;
    text-align: center;
    float: left;
    margin: 0px;
    padding: 25px 0px; }
  body {
    background: url(../media/mobile-bg.jpg) no-repeat;
    background-size: 100% auto; }
  .news-events .news-events-img {
    width: 100%;
    float: none;
    padding-left: 0;
    max-width: 100%;
    margin: 0 auto; }
  .news-events .news-events-content {
    padding-right: 0;
    width: 100%;
    padding-bottom: 20px; }
  .news-events {
    padding: 0px; }
  .our-station {
    padding: 30px 0px; }
  .our-station:before {
    background: transparent; }
  .page-template-default .site-content {
    padding: 0; }
  .page-template-default .bottom-section, .banner-section {
    padding: 30px 20px 20px;
    margin-top: -8px; }
  .error404 .bottom-section {
    padding: 30px 20px 20px; }
  .banner-section .banner-img {
    position: relative; }
  .banner-section .banner-img:before {
    content: "";
    position: absolute;
    bottom: -30px;
    left: -20px;
    right: -20px;
    background: #FFF;
    height: 110px;
    z-index: -1; }
  .banner-section {
    overflow: visible; }
  .news-events .news-events-img .news-img {
    margin-bottom: 25px; }
  .footer-left span {
    display: block; } }
