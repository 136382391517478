@media screen and (max-width:1200px) {
    .site-content, .site-header, .site-footer { max-width:930px;}
    .banner-section .banner-img { max-width: 565px; }
    .main-navigation ul li a { padding: 10px 15px;}
    .banner-section .banner-content { padding: 20px 22px 20px 22px;}
    .bottom-section .event-list-block {    max-width: 350px; padding: 0px 24px 0px 20px;}
    .news-events { width: 100%; float: left; padding: 40px 20px 70px 30px;}
    .news-events .news-events-content {padding-right: 10px;}
    .our-station { max-width: 315px; padding: 40px 25px 70px 25px;}

}
@media screen and (max-width:992px) {
    .site-content, .site-header, .site-footer { max-width: 740px; }
    .mobile-icon a, .header-call a { font-size:16px; color: #fff; font-weight: bold; text-transform: uppercase;}
    .mobile-icon a i, .header-call a i { margin-right: 10px; display: inline-block;}
    .mega-menu {  display: inline-block; width: 100%; margin-bottom: 10px; }
    .main-navigation { position: fixed; right: -250px; top: 0; height: 100%; width: 250px; -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; z-index: 19; background:rgba(0,0,0,0.9); text-align: left; overflow: auto; padding: 30px 0; margin: 0; overflow-x: hidden;}
    .open-menu .main-navigation { right: 0px;}
    .open-menu .fa-bars:before { content: "\f00d";}
    .site { -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; position: relative; left: 0;}
    .open-menu .site { left: -240px;}
    .main-navigation ul {text-align: left; display: block; width: 100%; }
    .main-navigation ul li { display: flex; flex-wrap: wrap; min-width: 0; width: 100%; }
    .main-navigation ul li a { font-size: 16px; flex: 1 1 0%; min-width: 0; }
    .main-navigation ul li button { display: inline-block; flex: 0 0 auto; }
    .main-navigation .sub-menu { background: transparent; display: none; flex: 0 0 100%; left: 0; padding-left: 20px; overflow: hidden; opacity: 1; position: static; top: 0; box-sizing: border-box; width: 100%; min-width: 0; }
    .main-navigation li.is-active .sub-menu { display: block; }
    .header-call { display: inline-block;}
    .banner-section .banner-content { width: 100%;}
    .banner-section .banner-img { max-width: 100%;}
    .bottom-section { display: block; }
    .bottom-section .colum-block.address-block, .colum-block.public-education, .bottom-section .event-list-block {
max-width: 100%; border-right: none; border-bottom: 1px solid #e0dfdf; padding: 0px 0px 20px; margin-bottom: 30px; width: 100%; }
    .colum-block.public-education:last-child { border-bottom: none; margin-bottom: 0px; padding-bottom: 0px;}
    body:before {height: 245px;}
    .our-station { padding: 0px 25px 30px 25px; background-color: #fff; max-width: 100%; }
	.our-station:before { left: -30px; }
    .news-events { padding:30px 20px 30px 30px; max-width: 100%;}
	.banner-section .btn-about { width: auto; padding: 9px 20px; }
}
@media screen and (max-width:767px) {
    .site-content, .site-header, .site-footer { max-width: 100%; padding: 0px 20px; }
     body { background-color: #fff; background-size: 100% auto; position: relative; }
    .top-address ul li {padding: 0px 4px; font-size: 8px;}
    .site-header .header-top { padding:10px 0px; }
    body:before { height: 130px;}
    .banner-section {box-shadow: none; margin-bottom: 0px; margin-top: 10px;}
    .banner-section .banner-content {    padding: 20px 0px 20px 0px;}
    h3 {font-size: 21px;}
    .banner-section .btn-about { width: 100%; float: left; text-align: center;}
    .banner-section .banner-content:before { display: none;}
	.bottom-section { box-shadow: none; padding:30px 0px 20px; }
    .bottom-section .colum-block.address-block, .colum-block.public-education, .bottom-section .event-list-block { border-bottom: none; padding: 0px;}
    .bottom-section .alignright, .bottom-section .alignleft, .bottom-section .aligncenter, .bottom-section .alignnone { float: none; margin: 0 auto; }
    .site-footer { background-color:#080b16; text-align: center;}
    .site-footer .footer-left {width: 100%;}
    .site-footer .footer-right { width: 100%;}
    .site-footer .footer-right ul { float: none; text-align:center;}
    .site-footer { background-color: #080b16; text-align: center; float: left; margin: 0px; padding: 25px 0px; }
    body { background: url(../media/mobile-bg.jpg) no-repeat; background-size: 100% auto;}
    .news-events .news-events-img { width: 100%; float: none; padding-left: 0; max-width: 100%; margin: 0 auto;}
    .news-events .news-events-content { padding-right: 0; width: 100%; padding-bottom: 20px; }
    .news-events { padding: 0px;}
    .our-station { padding: 30px 0px; }
	.our-station:before { background: transparent; }
	.page-template-default .site-content { padding: 0; }
	.page-template-default .bottom-section, .banner-section { padding: 30px 20px 20px; margin-top: -8px;}
    .error404 .bottom-section { padding: 30px 20px 20px; }
	.banner-section .banner-img { position: relative; }
	.banner-section .banner-img:before { content: "";position: absolute;bottom: -30px;left: -20px;right: -20px;background: #FFF;height: 110px;z-index: -1;}
	.banner-section { overflow: visible; }
	.news-events .news-events-img .news-img { margin-bottom: 25px; }
	.footer-left span { display: block; }
}
